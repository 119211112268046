<template>
  <div class="allmsg">
    <navigation :msg="msg" />
    <pre>
<h1>授权声明</h1>
    本人同意并授权应聘公司、<strong>上海猎后征信服务有限公司及其合作方基于职业背景调查的需要</strong>对我本人的信息进行查询并核实。

<h3>*一、授权内容</h3>
    1.基于您职业背景调查而填写的以上全部内容（包括但不仅限于身份信息：姓名、身份证号码、手机号等）。
    2.基于雇佣公司及应聘岗位所需的信息查询，例如负面社会安全记录、学历查询、过往履历及表现等。

<h3>*二、授权信息的使用及共享</h3>
    1.根据职业背景调查的需要，您同意将相关信息核验结果即《背景信息调查报告》返回给授权主体（一般为您的应聘公司）。
    2.在进行核验过程中，授权方会向合作方发送您的个人信息进行实名认证、负面社会安全记录等相关查询。
    3.信息接收方应采取合理措施（包括数据加密、访问控制、恶意代码防范、安全审计等）对您的个人信息进行严格保密。

<h3>三、其他</h3>
    1.您同意并完全理解本授权，尤其是标记*的条款，详细授权及隐私政策请阅读《隐私政策》。
    2.您承诺本授权由您本人亲自填写与授权，若由他人冒充进行授权，将依法追究他人的法律责任。
    3.您已告知并授权了解该事项的相关人士如实披露授权事项，请相关人员及部门和单位酌情予以配合，并免除上述人员及机构在信息提供过程中可能产生的法律责任。

        </pre
    >
  </div>
</template>
<script>
export default {
  data(){
    return{
      msg:"授权声明"
    }
  }
}
</script>
<style scoped>
.allmsg {
  background-color: #ffffff;
  overflow: hidden;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 0.5rem;
  font-size: 0.25rem;
  padding: 0.2rem;
}
</style>